import fetch from "auth/FetchInterceptor";
import { AUTH_TOKEN } from "constants/AuthConstant";
const UserService = {};
UserService.getUsers = function (page = 1) {
  return fetch({
    method: "get",
    url: `api/user/getAll?page=${page}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
UserService.login = function (data) {
  return fetch({
    url: "api/user/login",
    method: "post",
    data: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
UserService.getAllControllers = function () {
  return fetch({
    method: "get",
    url: `api/user/getAllControllers`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
UserService.createUser = function (data) {
  return fetch({
    method: "post",
    url: `api/user/create`,
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
UserService.getUser = function (id) {
  return fetch({
    method: "get",
    url: `api/user/getById/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
UserService.deleteUser = function (id) {
  return fetch({
    method: "delete",
    url: `api/user/deleteById/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
UserService.deleteUsers = function () {
  return fetch({
    method: "delete",
    url: `api/user/deleteAll`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
UserService.updateUser = function (id, data) {
  return fetch({
    method: "put",
    url: `api/user/updatePrivileges/${id}`,
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
export default UserService;
