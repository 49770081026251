import fetch from "auth/FetchInterceptor";
import { AUTH_TOKEN } from "constants/AuthConstant";
const AmbassadorService = {};
AmbassadorService.getAmbassadors = function (page = 1) {
  return fetch({
    method: "get",
    url: `api/becomeAnAmbassador/getAllAmbassadors?page=${page}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
AmbassadorService.getAmbassadorsRequests = function () {
  return fetch({
    method: "get",
    url: `api/becomeAnAmbassador/getAll?status=PENDING`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
AmbassadorService.deleteAmbassador = function (
  comapnyId,
  becomeAnAmbassadorId
) {
  return fetch({
    method: "delete",
    url: `api/becomeAnAmbassador/company/${comapnyId}/deleteAmbassadorById/${becomeAnAmbassadorId}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
AmbassadorService.getAmbassador = function (id) {
  return fetch({
    method: "get",
    url: `api/becomeAnAmbassador/company/getById/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
AmbassadorService.createAmbassador = function (data) {
  return fetch({
    method: "post",
    url: `api/becomeAnAmbassador/createAmbassador`,
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
AmbassadorService.validateAmbassador = function (id) {
  return fetch({
    method: "post",
    url: `api/becomeAnAmbassador/validateAmbassadorRequest/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
AmbassadorService.refuseAmbassador = function (id, data) {
  return fetch({
    method: "post",
    url: `api/becomeAnAmbassador/refuseAmbassadorRequest/${id}`,
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
export default AmbassadorService;
