import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import UserService from "services/UserService";

export const getAllUsers = createAsyncThunk(
  "users/getAllUsers",
  async (page = 1, { rejectWithValue }) => {
    try {
      const response = await UserService.getUsers(page);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
export const getAllControllers = createAsyncThunk(
  "users/getAllControllers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await UserService.getAllControllers();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
export const createUser = createAsyncThunk(
  "user/createUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await UserService.createUser(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);

export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async (id, { rejectWithValue }) => {
    try {
      await UserService.deleteUser(id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);

export const fetchOneUser = createAsyncThunk(
  "users/fetchOneUser",
  async (id, { rejectWithValue }) => {
    try {
      const response = await UserService.getUser(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
export const fetchOneUserToUpdate = createAsyncThunk(
  "users/fetchOneUserToUpdate",
  async (id, { rejectWithValue }) => {
    try {
      const response = await UserService.getUser(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
export const updateUser = createAsyncThunk(
  "users/updateUser",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await UserService.updateUser(id, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
const initialState = {
  controllers: [],
  users: [],
  user: {},
  userToUpdate: {},
  loading: false,
  error: null,
};

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    hideLoading: (state, action) => {
      state.loading = false;
    },
    hideError: (state, action) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch users
      .addCase(getAllUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload.data;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }) // fetch controllers
      .addCase(getAllControllers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllControllers.fulfilled, (state, action) => {
        state.loading = false;
        state.controllers = action.payload;
      })
      .addCase(getAllControllers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Create User
      .addCase(createUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.users.push(action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Delete user
      .addCase(deleteUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        const userId = action.payload;
        state.users = state.users.filter((user) => user._id !== userId);
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(fetchOneUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOneUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
      })
      .addCase(fetchOneUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchOneUserToUpdate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOneUserToUpdate.fulfilled, (state, action) => {
        state.loading = false;
        state.userToUpdate = action.payload;
        state.error = null;
      })
      .addCase(fetchOneUserToUpdate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Update service
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        const { id, updatedUser } = action.payload;
        const index = state.users.findIndex((user) => user.id === id);
        if (index !== -1) {
          state.users[index] = {
            ...state.users[index],
            ...updatedUser,
          };
        }
        state.loading = false;
        state.error = null;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default userSlice.reducer;
export const { showLoading, hideLoading, hideError } = userSlice.actions;
