import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import RelationshipService from "services/RelationshipService";

export const getAllRelationships = createAsyncThunk(
  "relationships/getAllRelationships",
  async (page = 1, { rejectWithValue }) => {
    try {
      const response = await RelationshipService.getRelationships(page);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
export const createRelationship = createAsyncThunk(
  "relationship/createRelationship",
  async (data, { rejectWithValue }) => {
    try {
      const response = await RelationshipService.createRelationshipWithService(
        data
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);

export const updateRelationship = createAsyncThunk(
  "relationships/updateRelationship",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await RelationshipService.updateRelationship(id, data);
      return { id, updatedRelationship: response.data };
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);

export const deleteRelationship = createAsyncThunk(
  "relationships/deleteRelationship",
  async (id, { rejectWithValue }) => {
    try {
      await RelationshipService.deleteRelationship(id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);

export const fetchOneRelationship = createAsyncThunk(
  "relationships/fetchOneRelationship",
  async (id, { rejectWithValue }) => {
    try {
      const response = await RelationshipService.getRelationship(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
export const validateRelationship = createAsyncThunk(
  "relationships/validateRelationship",
  async (id, { rejectWithValue }) => {
    try {
      const response = await RelationshipService.validateRelationship(id);
      return { id, updatedRelationship: response.data };
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
export const refuseRelationship = createAsyncThunk(
  "relationships/refuseRelationship",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await RelationshipService.refuseRelationship(id, data);
      return { id, updatedRelationship: response.data };
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
const initialState = {
  relationships: [],
  relationship: {},
  loading: false,
  error: null,
};

const relationshipSlice = createSlice({
  name: "relationships",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    hideLoading: (state, action) => {
      state.loading = false;
    },
    hideError: (state, action) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch relationships
      .addCase(getAllRelationships.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllRelationships.fulfilled, (state, action) => {
        state.loading = false;
        state.relationships = action.payload.data;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(getAllRelationships.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }) // Create Relationship
      .addCase(createRelationship.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createRelationship.fulfilled, (state, action) => {
        state.relationships.push(action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(createRelationship.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Update relationship
      .addCase(updateRelationship.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateRelationship.fulfilled, (state, action) => {
        const { id, updatedRelationship } = action.payload;
        const index = state.relationships.findIndex(
          (relationship) => relationship.id === id
        );
        if (index !== -1) {
          state.relationships[index] = {
            ...state.relationships[index],
            ...updatedRelationship,
          };
        }
        state.loading = false;
        state.error = null;
      })
      .addCase(updateRelationship.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Delete relationship
      .addCase(deleteRelationship.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteRelationship.fulfilled, (state, action) => {
        const relationshipId = action.payload;
        state.relationships = state.relationships.filter(
          (relationship) => relationship._id !== relationshipId
        );
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteRelationship.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(fetchOneRelationship.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOneRelationship.fulfilled, (state, action) => {
        state.loading = false;
        state.relationship = action.payload;
        state.error = null;
      })
      .addCase(fetchOneRelationship.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(validateRelationship.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(validateRelationship.fulfilled, (state, action) => {
        const { id, updatedRelationship } = action.payload;
        const index = state.relationships.findIndex(
          (relationship) => relationship.id === id
        );
        if (index !== -1) {
          state.relationships[index] = {
            ...state.relationships[index],
            ...updatedRelationship,
          };
        }
        state.loading = false;
        state.error = null;
      })
      .addCase(validateRelationship.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }) // refuse relationship
      .addCase(refuseRelationship.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(refuseRelationship.fulfilled, (state, action) => {
        const { id, updatedRelationship } = action.payload;
        const index = state.relationships.findIndex(
          (relationship) => relationship.id === id
        );
        if (index !== -1) {
          state.relationships[index] = {
            ...state.relationships[index],
            ...updatedRelationship,
          };
        }
        state.loading = false;
        state.error = null;
      })
      .addCase(refuseRelationship.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default relationshipSlice.reducer;
export const { showLoading, hideLoading, hideError } =
  relationshipSlice.actions;
