import { combineReducers } from "redux";
import theme from "./slices/themeSlice";
import auth from "./slices/authSlice";
import domains from "./slices/domainSlice";
import services from "./slices/serviceSlice";
import commercials from "./slices/commercialSlice";
import ambassadors from "./slices/ambassadorSlice";
import relationships from "./slices/relationshipSlice";
import messages from "./slices/messageSlice";
import users from "./slices/userSlice";
import promotions from "./slices/promotionSlice";
import invitations from "./slices/invitationSlice";
const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    domains,
    services,
    commercials,
    ambassadors,
    relationships,
    messages,
    users,
    promotions,
    invitations,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
